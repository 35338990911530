:root {
    --dark: #183a1d;
    --main: #fefbe9;
    --mint: #e1eedd;
    --accent-yellow: #f6c453;
    --accent-orange: #f0a04b;
}

* {
    margin: 0;
    padding: 0;
    text-decoration: none;
}

body {
    background-color: var(--main);
    background-size: 100%;
}

/* HEADER AND NAV START */
header {
    background-color: var(--main);
    display: flex;
    flex-wrap: wrap;
    top: 0;
    padding: 0 50px 0 0;
    font-family: 'Merriweather', serif;
}

header nav {
    margin: 10px auto;
}

header nav ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    list-style: none;
}

.nav-link {
    font-size: 38px;
    color: var(--accent-yellow);
    padding: 0px 50px 0 50px;
}

.active {
    color: var(--dark);
}

.header-title {
    font-family: 'Lobster', cursive;
    text-decoration: none;
    color: var(--accent-yellow);
    font-size: 2.5em;
    text-shadow: 1px 1px var(--dark);
}

.header-subtitle {
    font-family: 'Merriweather', serif;
    padding-bottom: 10px;
}

/* HEADER AND NAV END */

/* ABOUT ME START */

.portrait {
    align-items: center;
    background-color: var(--main);
    display: flex;
    flex-direction: row;
}

.portrait img {
    border: 9px dotted var(--accent-orange);
    border-radius: 300px;
    width: auto;
    height: 400px;
    margin: 0 10px 20px 60px;
}

.about-me {
    font-size: 15px;
    width: 60%;
    padding-left: 20px;
}

.about-me h1 {
    text-align: center;
    font-family: 'Merriweather', serif;
}

.moreInfo {
    align-items: center;
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: var(--mint);
    padding: 3%;
}

.bioHeading {
    font-family: 'Merriweather', serif;
    text-align: center;
    padding-bottom: 10px;
}

.bio {
    text-align: center;
    margin-left: 15px;
}

.moreInfo img {
    border: 8px dotted var(--accent-orange);
    height: 300px;
    margin: 40px;
}

.interests {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 25px;
    padding: 3% 3% 7% 3%;
    font-family: 'Merriweather', serif;
}

.allInterests {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.hobby-group {
    padding-top: 20px;
}

.interest-title {
    padding: 0 50px;
}

/* ABOUT ME END */

/* CONTACT START */

.contactMe {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding-top: 2%;
    background-color: var(--mint);
    padding-bottom: 10%;
}

.myEmail {
    color: black;
    text-decoration: dashed underline;
}

.contact p {
    font-weight: bold;
    font-size: 20px;
}

.contact-form {
    width: 500px;
    padding-top: 15px;
}

.contact-form h2 {
    font-family: 'Merriweather', serif;
}

.contact-form input,
.contact-form textarea {
    border: 1px solid var(--dark);
    display: block;
    padding: 6px 30px;
    font-size: 16px;
    color: var(--dark);
    margin: 0 0 10px 0;
    border-radius: 10px;
    background-color: white;
    width: 90%;
}

.contact-form button {
    padding: 10px 20px;
    background-color: #36454F;
    color: white;
    font-size: 16px;
    border: none;
    border-radius: 10px;
    margin-bottom: 20px;
}

.error-text {
    color: red;
}

.contacts {
    width: 25%;
}

.contactEmail {
    border: 5px solid var(--accent-yellow);
    padding: 10%;
    border-radius: 50px;
    text-align: center;
    width: fit-content;
}

.contactLinkedin {
    border: 5px solid var(--accent-yellow);
    padding: 10%;
    margin-top: 20%;
    border-radius: 50px;
    text-align: center;
    width: max-content;
}

.contacts a {
    color: var(--accent-orange);
}

/* CONTACT END */

/* PORTFOLIO START */

.portfolio {
    background-color: var(--mint);
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    gap: 2rem;
    justify-content: center;
    padding-bottom: 10%;
    padding-top: 20px;
}

.card {
    overflow: hidden;
    box-shadow: 0 2px 20px darkgrey;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    transition: transform 200ms ease-in;
    height: 100%;
}

.card:hover {
    transform: scale(1.05);
}

.cardTitle {
    font-family: 'Merriweather', serif;
    font-size: 40px;
    text-align: center;
    padding: 2%;
}

.description {
    font-size: 18px;
    text-align: center;
    line-height: normal;
}

.skillsUsed {
    font-size: 14px;
    text-align: center;
    line-height: normal;
}

.btns {
    display: inline;
    justify-content: center;
    margin: 0 3% 2% 3%;
}

.githubLink {
    width: 45%;
    background-color: var(--dark);
    color: white;
    font-size: 16px;
    border: none;
    border-radius: 10px;
}

.liveSite {
    width: 45%;
    background-color: var(--dark);
    color: white;
    font-size: 16px;
    border: none;
    border-radius: 10px;
    float: right;
}

/* PORTFOLIO END */

/* RESUME START */

.resumeContainer {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 2%;
    padding-bottom: 8%;
    background-color: var(--mint);
}

.resume {
    text-align: center;
    border: 5px solid var(--accent-yellow);
    border-radius: 50px;
    padding: 5%;
}

.resumeIcon {
    color: var(--accent-orange);
}

.skills h2 {
    font-family: 'Merriweather', serif;
}

/* RESUME END */

/* FOOTER START */
footer {
    background-color: var(--accent-orange);
    bottom: 0;
    height: 60px;
    position: fixed;
    width: 100%;
    align-items: center;
}

.allIcons {
    justify-content: space-evenly;
    margin: auto;
}

.socialIcons {
    height: 40px;
    align-items: center;
    padding: 0 15px;
}

.copyright {
    margin: auto;
    font-weight: bold;
    color: var(--dark);
    align-items: center;
    justify-content: flex-end;
}

/* FOOTER END */



/* RESPONSIVE */


/*MEDIA QUERY FOR SMALLER DESKTOP SCREENS AND SMALLER */
@media screen and (max-width: 980px) {
    .header-title {
        margin-left: 10%;
        margin-right: 10%;
        text-align: center;
        line-height: 1;
        padding-bottom: 2%;
    }

    .portrait {
        flex-direction: column;
        margin-left: 10%;
        margin-right: 10%;
        text-align: center;
        line-height: 1;
    }

    .portrait img {
        margin: auto;
    }

    .about-me {
        width: 100%;
        margin-top: 10px;
    }

    .moreInfo {
        flex-direction: column;
        margin: auto;
        text-align: center;
        line-height: 1;
    }

    .allInterests {
        flex-direction: column;
        padding-bottom: 5%;
    }

    .contactMe {
        flex-direction: column;
        margin: auto;
        text-align: center;
        gap: 1rem;
    }

    .contact-form {
        margin: auto;
        width: -webkit-fill-available;
        padding-left: 20px;
    }

    .contacts {
        width: 30%;
        padding-bottom: 5%;
    }
}

/*MEDIA QUERY FOR TABLETS AND SMALLER */
@media screen and (max-width: 768px) {
    .resumeContainer {
        flex-direction: column;
        margin-left: 10%;
        margin-right: 10%;
        text-align: center;
        line-height: 1;
        gap: 3rem;
        padding-bottom: 10%;
    }

    .portfolio {
        padding-bottom: 20%;
    }

    .portrait img {
        height: 350px;
    }

}

/*MEDIA QUERY FOR MOBILE PHONES AND SMALLER */
@media screen and (max-width: 480px) {
    .contacts {
        width: 40%;
    }

    .about-me h1 {
        padding-top: 10px;
    }

    .contactLinkedin {
        margin-bottom: 20%;
    }

    .resumeContainer {
        padding-bottom: 20%;
    }
}